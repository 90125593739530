<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
      <div class="card card-body col-12 table-responsive">
        <div>
          <br />
          <table class="table table-bordered">
            <tbody>
              <tr style="border: solid 2px #777">
                <th>رابط الـ API</th>
                <th>
                  <h5 v-if="user.exdate == ''" class="text-danger text-center">
                    يجب عليك الاشتراك اولاً لعرض الـ API.
                  </h5>
                  <a
                    v-if="user.exdate != ''"
                    href="https://documenter.getpostman.com/view/14352330/2s8YzXuKNB"
                    target="_blank"
                    >https://documenter.getpostman.com/v....</a
                  >
                </th>
              </tr>
              <tr style="border: solid 2px #777">
                <th>الـ JWT الخاص بك</th>
                <th>
                  <input
                    type="text"
                    class="form-control bg-white selectme"
                    readonly
                    v-model="user.jwt"
                  />
                </th>
              </tr>
            </tbody>
          </table>
          <br />
          <p>ارقام الاجهزة</p>
          <table class="table table-bordered">
            <tbody>
              <template v-for="device in devices">
                <tr :key="device._id">
                  <th>
                    {{ device.title }}
                  </th>
                  <th>
                    <input
                      type="text"
                      class="form-control bg-white selectme"
                      readonly
                      v-model="device._id"
                    />
                  </th>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card card-body text-right" dir="ltr">
        HTML open chat of special number widget:
        <br />
        <code>
          https://app.smart-whats.com/widget/{jwt}/{device_id}/{phone}#name
        </code>
        <br /><br />Example:

        <code>
          https://app.smart-whats.com/widget/sAWIVmb0RABoKmamWL80aRysw/63ebd32d186b33e9d128/966580200670#ahmed
        </code>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      devices: [],
    };
  },
  created() {
    var g = this;
    $.post(api + "/user/devices/list", {
      jwt: this.user.jwt,
    })
      .then(function (r) {
        g.devices = r.response;
        g.loading = false;
        setTimeout(() => {
          $(".selectme").click(function () {
            $(this)[0].select();
          });
        }, 500);
      })
      .catch(function () {
        g.loading = false;
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "حدثت مشكلة في الاتصال",
            icon: "EditIcon",
            variant: "danger",
          },
        });
      });
  },
};
</script>

<style>
</style>